import React from 'react';
import { Grid, Button } from '@material-ui/core';

const Tipos = {
    perfil: {
        nombreBoton: 'Subir Nueva Foto de Perfil',
        idInput: 'contained-button-perfil',
        nombreFuncionOnChange: 'perfil',
        alt: 'FotoPerfil',
    },
    portada: {
        nombreBoton: 'Subir Nueva Foto de Portada',
        idInput: 'contained-button-portada',
        nombreFuncionOnChange: 'portada',
        alt: 'FotoPortada',
    },
}

const SubirFoto = ({ tipo, imagenGuardada, onChageFoto }) => {

    return (
        <Grid item xs={10} sm={10} md={6} lg={6}>
            <Grid item>
                <input accept="image/png, image/jpeg" style={{ display: 'none' }} id={Tipos[tipo].idInput}
                    type="file" onChange={(e) => onChageFoto(Tipos[tipo].nombreFuncionOnChange, e)} />

                <label htmlFor={Tipos[tipo].idInput}>
                    <Button variant="contained" color="primary" component="span">
                        {Tipos[tipo].nombreBoton}
                    </Button>
                </label>
            </Grid>
            <Grid container direction="row" alignItems="flex-start">
                <Grid>
                    {imagenGuardada &&
                        <img src={imagenGuardada} alt={Tipos[tipo].alt} />
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SubirFoto;