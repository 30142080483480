import React from 'react';
import { useAuth } from './../contexto/AuthContext';
import { Route, Redirect } from 'react-router-dom';

const RutaPrivada = ({ children, ...restoDePropiedades }) => {
    const { usuario } = useAuth();

    if (usuario) {
        return <Route {...restoDePropiedades}>{children}</Route>
    } else {
        return <Redirect to="/login" />
    }

}

export default RutaPrivada;