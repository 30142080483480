import React, { useState } from 'react';
import BotonCerrarSesion from 'elementos/BotonCerrarSesion';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { GuardarImagen, GuardarTalento, EliminarTalento } from 'firebase/funcionesFirebase';
import { useHistory } from 'react-router-dom';
import useObtenerTalento from 'hooks/useObtenerTalento';
import { useNotifications } from 'reapop';
import { InputTextoSinAdorno, InputTextoConAdorno, InputTextArea } from 'elementos/Inputs';
import SubirFoto from 'elementos/SubirFoto';
import BotonEliminarTalento from 'elementos/BotonEliminarTalento';

const useStyles = makeStyles({
    background: {
        backgroundColor: '#0093E9',
        backgroundImage: 'linear-gradient( 89.2deg,  rgba(5,120,190,1) 3.3%, rgba(119,217,211,1) 97.8% )',
        minHeight: '100vh',
        width: '100%',
        maxWidth: '100vw',

    },
    paper: {
        padding: '50px 40px',
        margin: '60px 40px',
        width: '90vw',
        maxWidth: '70vw',
        // maxHeight: '90vh',
        // overflow: 'auto'
    },
    tabla: {
        height: 'auto',
        boxShadow: 'none'
    },
    boton: {
        marginTop: 20,
    },
})

const Talento = () => {
    const classes = useStyles();
    const history = useHistory();
    const { notify } = useNotifications();
    const { id } = useParams();
    const { datos, cambiarDatos } = useObtenerTalento(id, notify, history)
    const [cargando, cambiarCargando] = useState(false)

    const cambiarCampo = (campo, valorNuevo) => {
        const NuevosDatos = { ...datos }
        NuevosDatos[campo] = valorNuevo
        cambiarDatos(NuevosDatos)
    }

    const validarCampos = (e) => {
        e.preventDefault();
        if (datos.nombre === '' || datos.profesion === '') {
            notify({ title: '¡Campos No Validos!', message: 'El nombre o profesion no puede estar vacio.', dismissible: true, status: 'error', dismissAfter: 5000 })
            return
        } else {
            GuardarTalento(id, datos, notify, history, cambiarCargando);
        }
    }

    const onChageFoto = (tipo, e) => {
        GuardarImagen(e.target.files[0], notify, tipo, cambiarCampo, cambiarCargando)
    }

    return (
        <Grid className={classes.background} container justifyContent="center" alignItems="center" component="main">
            <BotonCerrarSesion />
            <Grid item={true} xs={11} sm={11} md={9} lg={9} component={Paper} className={`${classes.paper} fade-in-bottom`}>
                <Grid container alignItems="center">
                    <ArrowBackIcon titleAccess="Regresar sin Guardar" style={{ marginRight: 10 }} className="pointer" onClick={() => history.push('/talentos')} />
                    <Typography component="h1" align="left" variant="h5">{id ? 'Editar' : 'Nuevo'}</Typography>
                </Grid>
                <form noValidate>
                    <Grid container spacing={2}>
                        <InputTextoSinAdorno label="Nombre Completo" name="nombre" autoFocus value={datos.nombre} onChange={(e) => cambiarCampo('nombre', e.target.value)} />
                        <InputTextoSinAdorno label="Profesión" name="profesion" value={datos.profesion} onChange={(e) => cambiarCampo('profesion', e.target.value)} />
                        <InputTextoConAdorno name="facebook" label="Link Facebook" value={datos.facebook} onChange={(e) => cambiarCampo('facebook', e.target.value)} />
                        <InputTextoConAdorno name="twitter" label="Link Twitter" value={datos.twitter} onChange={(e) => cambiarCampo('twitter', e.target.value)} />
                        <InputTextoConAdorno name="instagram" label="Link Instagram" value={datos.instagram} onChange={(e) => cambiarCampo('instagram', e.target.value)} />
                        <InputTextoConAdorno name="youtube" label="Link Youtube" value={datos.youtube} onChange={(e) => cambiarCampo('youtube', e.target.value)} />
                        <InputTextArea name="espanol" label="Descripción Español" value={datos.espanol} onChange={(e) => cambiarCampo('espanol', e.target.value)} />
                        <InputTextArea name="ingles" label="Descripción Inglés" value={datos.ingles} onChange={(e) => cambiarCampo('ingles', e.target.value)} />
                    </Grid>
                    <Grid container justifyContent="center">
                        <SubirFoto tipo="perfil" imagenGuardada={datos.fotoperfil} onChageFoto={onChageFoto} />
                        <SubirFoto tipo="portada" imagenGuardada={datos.fotoportada} onChageFoto={onChageFoto} />
                    </Grid>
                    <Button type="button" fullWidth variant="contained" color="primary" disabled={cargando} className={classes.boton}
                        onClick={(e) => validarCampos(e)}
                    >
                        Guardar
                    </Button>
                </form>
            </Grid>
            <BotonEliminarTalento id={id} onClick={() => EliminarTalento(id, history, notify, cambiarCargando)} />
        </Grid>
    );
}

export default Talento;