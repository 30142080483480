import React from 'react';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';

const InputTextoSinAdorno = (props) => {
    // console.log(props);
    return (
        <Grid item xs={12} sm={6}>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                {...props}
            />
        </Grid>
    );
}
const InputTextoConAdorno = (props) => {
    const Icono = () => {
        switch (props.name) {
            case 'facebook':
                return (<FacebookIcon />)
            case 'twitter':
                return (<TwitterIcon />)
            case 'instagram':
                return (<InstagramIcon />)
            case 'youtube':
                return (<YouTubeIcon />)

            default:
                return (<FacebookIcon />)
        }
    }

    return (
        <Grid item xs={12} sm={6}>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="url"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {Icono()}
                        </InputAdornment>
                    ),
                }}
                {...props}
            />
        </Grid>
    );
}
const InputTextArea = (props) => {
    return (
        <Grid item xs={12} sm={6}>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                multiline
                minRows={5}
                maxRows={25}
                {...props}
            />
        </Grid>
    );
}

export { InputTextoSinAdorno, InputTextoConAdorno, InputTextArea };