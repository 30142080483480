import { db } from 'firebase/firebaseConfig';
import { collection, addDoc, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const GuardarTalento = async (id, datos, notify, history, cambiarCargando) => {
    cambiarCargando(true)
    const notification = notify({ title: 'Guardando...', message: 'Espera un momento por favor.', dismissible: false, status: 'loading' })
    if (id) {
        await updateDoc(doc(db, 'talentos', id), datos)
            .then(res => {
                notification.status = 'success'; notification.title = '¡Guardado Éxitoso!'; notification.message = 'La información fue guardada correctamente'; notification.dismissible = true; notification.dismissAfter = 5000;
                notify(notification)
                return history.push('/talentos')
            })
            .catch(err => {
                console.log(err)
                notification.status = 'error'; notification.title = '¡Error!'; notification.message = 'Ocurrió un error al guardar la información'; notification.dismissible = true; notification.dismissAfter = 5000;
                notify(notification)
                return cambiarCargando(false)
            })
    } else {
        await addDoc(collection(db, "talentos"), datos)
            .then(res => {
                notification.status = 'success'; notification.title = '¡Guardado Éxitoso!'; notification.message = 'La información fue guardada correctamente'; notification.dismissible = true; notification.dismissAfter = 5000;
                notify(notification)
                return history.push('/talentos')
            })
            .catch(err => {
                console.log(err)
                notification.status = 'error'; notification.title = '¡Error!'; notification.message = 'Ocurrió un error al guardar la información'; notification.dismissible = true; notification.dismissAfter = 5000;
                notify(notification)
                return cambiarCargando(false)
            })
    }
}

const GuardarImagen = async (foto, notify, tipo, cambiarCampo, cambiarCargando) => {
    cambiarCargando(true)
    const notification = notify({ title: 'Validando Imagen...', message: 'Espera un momento por favor.', dismissible: false, status: 'loading' })
    const storage = getStorage();
    const imageRef = ref(storage, tipo + '/' + (foto.name + Math.floor(Math.random() * (5 - 1)) + 1));
    await uploadBytesResumable(imageRef, foto)
        .then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                cambiarCampo(tipo === 'perfil' ? 'fotoperfil' : 'fotoportada', url)
                // cambiarLinkFotoPerfil(url)
                notification.status = 'success'; notification.title = '¡La Imagen es Correcta!'; notification.message = 'La imagen fue procesada correctamente.'; notification.dismissible = true; notification.dismissAfter = 5000;
                notify(notification)
                return cambiarCargando(false)
            });
        }).catch((error) => {
            console.error('Upload failed', error);
            notification.status = 'error'; notification.title = '¡Ocurrio un error!'; notification.message = 'La imagen no pudo ser procesada.'; notification.dismissible = true; notification.dismissAfter = 5000;
            notify(notification)
            return cambiarCargando(false)
        });

}

const EliminarTalento = async (id, history, notify, cambiarCargando) => {
    cambiarCargando(true)
    const notification = notify({ title: 'Eliminando de la Base de Datos...', message: 'Espera un momento por favor.', dismissible: false, status: 'loading' })
    await deleteDoc(doc(db, 'talentos', id))
        .then(res => {
            notification.status = 'success'; notification.title = '¡Eliminado Correctamente!'; notification.message = 'El registro fue eliminado de la base de datos.'; notification.dismissible = true; notification.dismissAfter = 5000;
            notify(notification)
            return history.push('/talentos')
        })
        .catch(err => {
            console.log(err);
            notification.status = 'error'; notification.title = '¡Error!'; notification.message = 'El registro fue eliminado de la base de datos.'; notification.dismissible = true; notification.dismissAfter = 5000;
            notify(notification)
            return cambiarCargando(false)
        })
}

export { GuardarImagen, GuardarTalento, EliminarTalento };