import React from 'react';
import { auth } from '../firebase/firebaseConfig';
import { signOut } from 'firebase/auth';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {useNotifications} from 'reapop';

const useStyles = makeStyles({
    boton: {
        backgroundColor: '#1A2980',
        border: 0,
        position: 'fixed',
        top: '15px',
        right: '15px',
        // borderRadius: 3,
        boxShadow: '0 3px 5px 2px #0093E931',
        color: 'white',
        height: 52,
        padding: '0 20px',
        // marginTop: '10px',
        '&:hover': {
            backgroundColor: '#E80505',
        },
    },
})

const BotonCerrarSesion = () => {
    const classes = useStyles();
    const { notify } = useNotifications()
    const cerrarSesion = async () => {
        try {
            await signOut(auth);
            notify({title: 'Sesión Cerrada', dismissAfter: 5000, dismissible: true, status: 'info'})
        } catch (error) {
            console.log(error)
            notify({title: 'Ocurrió un problema', dismissAfter: 5000, dismissible: true, status: 'error'})
        }
    }

    return (
        <Fab className={`${classes.boton} fade-in`} variant="extended" onClick={() => cerrarSesion()}>
            <ExitToAppIcon style={{ paddingRight: 10 }} /> Cerrar Sesión
        </Fab>
    );
}

export default BotonCerrarSesion;