import React, { useState } from 'react';
import { Avatar, Button, TextField, Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { auth } from '../firebase/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNotifications } from 'reapop';

const useStyles = makeStyles({
    boton: {
        backgroundColor: '#0093E9',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px #0093E931',
        color: 'white',
        height: 48,
        padding: '0 30px',
        marginTop: '10px',
        '&:hover': {
            backgroundColor: '#0476b8',
        },
    },
    background: {
        backgroundColor: '#0093E9',
        backgroundImage: 'linear-gradient( 109.6deg,  rgba(61,245,167,1) 11.2%, rgba(9,111,224,1) 91.1% )',
        height: '100vh',
        width: '100vw',
    },
    titulo: {
        margin: '10px 0',
    },
    icono: {
        width: '60px',
        height: '60px',
        backgroundColor: '#0093E9',
    },
});

const Login = () => {
    const classes = useStyles();
    const history = useHistory();
    const [correo, cambiarCorreo] = useState('')
    const [password, cambiarPassword] = useState('')
    const { notify } = useNotifications()

    const handleSubmit = async (e) => {
        e.preventDefault();
        const notification = notify({ title: 'Validando...', message: 'Estamos comprobando tus credenciales, espera un momento.', dismissible: false, status: 'loading' })
        //COMPROBAMOS DEL LADO DEL CLIENTE SI EL CORREO SEA VALIDO
        const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
        if (!expresionRegular.test(correo)) {
            notification.status = 'error'; notification.title = '¡Ocurrio un error!'; notification.message = 'El formato del correo no es valido.'; notification.dismissible = true; notification.dismissAfter = 5000;
            notify(notification)
            return 
        }

        if (correo === '' || password === '') {
            notification.status = 'error'; notification.title = '¡Ocurrio un error!'; notification.message = 'El correo o password no puede estar vacio.'; notification.dismissible = true; notification.dismissAfter = 5000;
            notify(notification)
            return
        }

        try {
            await signInWithEmailAndPassword(auth, correo, password)
            console.log('Usuario loggeado con éxito')
            notification.status = 'success'; notification.title = '¡Todo Listo!'; notification.message = 'Verificamos tus credenciales y están correctas.'; notification.dismissible = true; notification.dismissAfter = 5000;
            notify(notification)
            setTimeout(() => {
                history.push('/');
            }, 1000);
        } catch (error) {
            let mensaje;
            switch (error.code) {
                case 'auth/wrong-password':
                    mensaje = 'La contraseña no es correcta.'
                    break;
                case 'auth/user-not-found':
                    mensaje = 'No se encontro ninguna cuenta con este correo electrónico.'
                    break;
                default:
                    mensaje = 'Hubo un error. (' + error + ')'
                    break;
            }
            notification.status = 'error'; notification.title = '¡Ocurrió un problema!'; notification.message = mensaje; notification.dismissible = true; notification.dismissAfter = 5000;
            notify(notification)
            console.log(mensaje)
        }
    }

    return (
        <Grid className={classes.background} container justifyContent="center" alignItems="center" component="main">
            <Grid item xs={9} sm={6} md={4} lg={3} className="fade-in-bottom" component={Paper} elevation={6} square={false}>
                <div style={{ padding: '60px 40px' }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Avatar className={classes.icono} sizes={'large'} />
                    </Grid>
                    <Typography component="h1" align="center" variant="h5" className={classes.titulo}>Ingresar</Typography>
                    <form noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo Electronico"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={correo} onChange={(e) => cambiarCorreo(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password} onChange={(e) => cambiarPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.boton}
                            onClick={(e) => handleSubmit(e)}
                        >
                            Ingresar
                        </Button>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

export default Login;