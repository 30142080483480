import React from 'react';
// Notificaciones
import { NotificationsProvider, setUpNotifications } from 'reapop';
import Notificacion from 'elementos/Notificacion';


import './App.css';
import Login from './components/Login';
import RutaPrivada from './components/RutaPrivada';
import Talentos from './components/Talentos';
import Talento from './components/Talento';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { AuthProvider } from './contexto/AuthContext';


const App = () => {

  setUpNotifications({
    defaultProps: {
      position: 'top-right',
      dismissible: true
    }
  });

  return (
    <>
      <NotificationsProvider>
        <AuthProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <RutaPrivada path="/" exact>
                <Redirect to="/talentos" />
              </RutaPrivada>
              <RutaPrivada path="/talentos">
                <Talentos />
              </RutaPrivada>
              <RutaPrivada path="/talento/:id?">
                <Talento />
              </RutaPrivada>
            </Switch>
            <Notificacion />
          </BrowserRouter>
        </AuthProvider>
      </NotificationsProvider>
    </>
  );
}

export default App;
