import { useEffect, useState } from 'react';
import { db } from '../firebase/firebaseConfig';
import { collection, onSnapshot } from 'firebase/firestore';

const useObtenerListaTalentos = () => {
    const [listaTalentos, cambiarListaTalentos] = useState([])
    
    useEffect(() => {
        const obtenerDatos = async () => {
            const unsubscribe = await onSnapshot(collection(db, 'talentos'), (talentos) => {
                const arrayTalentos = []
                talentos.forEach((talento) => {
                    arrayTalentos.push({...talento.data(), id: talento.id});
                });
                cambiarListaTalentos(arrayTalentos);
            })
            return unsubscribe
        }
        return obtenerDatos();
    }, [])

    return { listaTalentos }

}

export default useObtenerListaTalentos;