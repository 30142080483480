import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles({
    boton: {
        backgroundColor: '#C50E19',
        border: 0,
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        // borderRadius: 3,
        boxShadow: '0 3px 5px 2px #C50E1931',
        color: 'white',
        height: 48,
        padding: '0 20px',
        // marginTop: '10px',
        '&:hover': {
            backgroundColor: '#851B27',
        },
    },
})

const BotonEliminarTalento = (props) => {
    const classes = useStyles();
    const {id} = props
    return (
        <Fab className={`${classes.boton} fade-in`} disabled={id ? false : true} variant="extended" {...props}>
            <AddIcon style={{ paddingRight: 10 }} />Eliminar Registro
        </Fab>
    );
}

export default BotonEliminarTalento;