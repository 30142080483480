import { useEffect, useState } from 'react';
import { db } from '../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const useObtenerTalento = (id, notify, history) => {
    const [datos, cambiarDatos] = useState({
        nombre: '',
        profesion: '',
        facebook: '',
        twitter: '',
        instagram: '',
        youtube: '',
        espanol: '',
        ingles: '',
        fotoperfil: '',
        fotoportada: '',
    })

    useEffect(() => {
        const obtenerDatos = async () => {
            if (id) {
                const docRef = doc(db, 'talentos', id);
                const datos = await getDoc(docRef);

                if (datos.exists()) {
                    // console.log('Documento encontrado crack!');
                    cambiarDatos({...datos.data()});
                } else {
                    console.log('No encontramos el documento en firestore');
                    notify({ title: '¡Ocurrio un error!', message: 'No fue posible encontrar el usuario en la base de datos.', dismissible: true, status: 'error', dismissAfter: 5000 })
                    return history.push('/talentos')
                }
            }
        }
        obtenerDatos();
    }, [id])

    return { datos, cambiarDatos }

}

export default useObtenerTalento;