import React from 'react';
import BotonCerrarSesion from 'elementos/BotonCerrarSesion';
import BotonAgregarTalento from 'elementos/BotonAgregarTalento';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useObtenerListaTalentos from 'hooks/useObtenerListaTalentos';
import MUIDataTable from "mui-datatables";
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    background: {
        backgroundColor: '#0093E9',
        backgroundImage: 'linear-gradient( 89.2deg,  rgba(5,120,190,1) 3.3%, rgba(119,217,211,1) 97.8% )',
        minHeight: '100vh',
        width: '100%',
        maxWidth: '100vw',
    },
    paper: {
        padding: '50px 40px',
        margin: '40px 0px',
    },
    tabla: {
        boxShadow: 'none'
    },
    btneditar: {
        cursor: 'pointer'
    },
})

const options = {
    selectableRows: 'none', download: 'false', print: 'false', viewColumns: 'false', filter: 'false',
    rowsPerPageOptions: [10, 20, 30],
    textLabels: {
        body: {
            noMatch: "No se encontraron coincidencias",
            toolTip: "Acomodar",
            columnHeaderTooltip: column => `Acomodar por ${column.label}`
        },
        pagination: {
            next: "Siguiente Página",
            previous: "Anterior Página",
            rowsPerPage: "Resultados por Página:",
            displayRows: "de",
        },
        toolbar: {
            search: "Buscar",
        },
    }
}


const Talentos = () => {
    const { listaTalentos } = useObtenerListaTalentos();
    const classes = useStyles();
    const history = useHistory();

    const EditarTalento = (valor) => {
        const talento = listaTalentos.find((talento, index) => index === valor)
        history.push(`/talento/${talento.id}`)
    }

    const columns = [
        {
            name: "nombre",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "Editar",
            alignItems: 'end',
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Grid container justifyContent="flex-end">
                            <EditIcon className={classes.btneditar} onClick={() => EditarTalento(dataIndex)} />
                        </Grid>
                    );
                },
                customHeadLabelRender: () => {
                    return (
                        <Grid container justifyContent="flex-end">
                            Editar
                        </Grid>
                    );
                }
            }
        },
    ];

    return (
        <Grid className={classes.background} container justifyContent="center" alignItems="center" component="main">
            <BotonCerrarSesion />
            <Grid item xs={11} sm={11} md={9} lg={9} component={Paper} className={`${classes.paper} fade-in-bottom`}>
                <MUIDataTable
                    title="Lista de Talentos"
                    className={classes.tabla}
                    data={listaTalentos}
                    columns={columns}
                    options={options}
                />
            </Grid>
            <BotonAgregarTalento onClick={() => history.push('/talento')} />
        </Grid>
    );
}

export default Talentos;